import { getApp, initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCsuwJD1vWfw87zKIrPs5b6-bA2cjyb_i0",
    authDomain: "kanta-personal-portfolio.firebaseapp.com",
    projectId: "kanta-personal-portfolio",
    storageBucket: "kanta-personal-portfolio.appspot.com",
    messagingSenderId: "539515659449",
    appId: "1:539515659449:web:72da3a28c09b4bec566768",
    measurementId: "G-F559CYSDQ4"
  };

const app = initializeApp(firebaseConfig);
const firebaseApp = getApp(app.name);
const firestore = getFirestore(app);
const firebaseAuth = getAuth(app);
const storage = getStorage(app);
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

export {
    firebaseApp,
    firestore,
    storage,
    firebaseAuth,
    analytics,
}