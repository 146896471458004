const bioData = {
  yearOfExperience: 7,
  name: "Elvis Sarfo",
  email: "sarfoantwielvis@gmail.com",
  phone: "+233 54 307 5757",
  location: "Kumasi, Ashanti, Ghana",
  description: `A passionate full-stack developer who crafts innovative digital solutions that are both functional and visually appealing. With a diverse skillset and a commitment to continuous learning, I transform ideas into dynamic, user-centric applications. Let's collaborate to create software that drives innovation and enhances user experiences.`,
  socialLinks: {
    github: "https://github.com/Elvis-Sarfo",
    gitlab: "https://gitlab.com/elvis-sarfo",
    linkedin: "https://linkedin.com/in/elvis-sarfo",
    twitter: "https://twitter.com/_elvisSarfo",
    facebook: "",
    instagram: "",
  }
};

export default bioData;