export const educationDetails = [
  {
    makePublic: true,
    yearRange: "Febuary 2023 - Present",
    title: "Master of Philosophy, Information Technology",
    place: "AAMUSTED, Kumasi",
    desc: `Possesses a comprehensive understanding of advanced web technologies, 
      advanced software engineering principles, advanced database management, advanced data structures and algorithms, 
      information security and other core software and data enginerring concepts and completing the courseworks mention above and others. 
      Demonstrates expertise conducting research in machine learning and homomorphic encryption.`,
  },
  {
    makePublic: true,
    yearRange: "September 2017 - June 2021",
    title: "Bachelor of Science, Information Technology Education",
    place: "University of Education Winneba, Kumasi",
    desc: `Information Technology major with a minor in Interdisciplinary Studies, 
      holding a cumulative GPA of 3.91. Completed coursework in Data Structures and Algorithms, 
      Discrete Math, Numerical Methods and Computations, Software Engineering, 
      Distributed Systems, and Information Security.`,
  },
  {
    makePublic: true,
    yearRange: "October 2018 – January 2019",
    title: "CCNA R&S",
    place: "Cisco Networking Academy, UEW, COLTEK",
    desc: "Certified for the completion of CCNA R&S: Introduction to Networks, showcasing proficiency in foundational networking concepts.",
  },
];

export const experienceDetails = [
  {
    makePublic: false,
    yearRange: "November 2022 - November 2023",
    title: "Frontend Engineer",
    place: "Kline and Company, USA",
    desc: `
      Successfully integrated data visualization charts and improved forecasting features in an Angular application, 
      enhancing user experience. Played a key role in optimizing Angular application performance, 
      actively contributing to code reviews and receiving recognition for outstanding contributions to feature 
      development and performance improvements.`,
  },
  {
    makePublic: true,
    yearRange: "September 2020 - Present",
    title: "Co-Founder and Tech Lead ",
    place: "FarmAsyst Company Limited, Ghana",
    desc: `Directed the development of a secured platform for monitoring agricultural projects, 
      addressing client-specific needs. Led the creation of a Flutter mobile app for agricultural supervisors, 
      ensuring data integrity, and developed a React admin dashboard for report analysis. 
      Configured Firebase into a Node.js backend, optimizing real-time communication between applications.`,
  },
  {
    makePublic: true,
    yearRange: "September 2021 - September 2022",
    title: "Instructor",
    place: "INVEST IN HER Project, Kumasi, Ghana",
    desc: `Played a crucial role in empowering over 70 female students with programming and web development skills. 
      I provided comprehensive lessons, practical applications, and individualized mentorship to nurture their technical 
      expertise and guide them towards becoming professional web developers. My contributions significantly advanced 
      female education in technology, extending beyond teaching to encompass curriculum development and mentorship initiatives.`,
  },
  {
    makePublic: true,
    yearRange: "September 2020 - June 2021",
    title: "Mentee & Team Lead",
    place: "Google Developers",
    desc: `Successfully transformed a team of 4 into a startup to enhance a project enabling users to report health emergencies in Ghana. 
      I also collaborated with Google engineers to re-engineer the architecture of the whole software, improving performance, security and scalability.`,
  },
];

export const skills = [
  {
    name: "Web Design",
    percent: 65,
  },
  {
    name: "HTML/CSS",
    percent: 95,
  },
  {
    name: "JavaScript",
    percent: 80,
  },
  {
    name: "React JS",
    percent: 70,
  },
  {
    name: "Angular Js",
    percent: 60,
  },
  {
    name: "Bootstrap",
    percent: 99,
  },
];
