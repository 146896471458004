import React, { useState } from "react";
import ProjectDetailsModal from "./ProjectDetailsModal";
import { projectsData, types, visibility } from "../data/projects";


const Portfolio = () => {
  const initalItems = 3;
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [numOfProjectsShown, setNumOfProjectsShown] = useState(initalItems);

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.slice(0, numOfProjectsShown).map((project, index) =>
                  project.visibility === visibility.PUBLIC ? (
                    <div
                      className={"col-sm-6 col-lg-4 filter-item"}
                      key={index}
                    >
                      <div onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                              setIsOpen(true);
                            }} className="portfolio-box">
                        <div className="portfolio-img">
                          <img
                            onLoad={() => {
                              setImagesLoaded(imagesLoaded + 1);
                            }}
                            className="img-fluid d-block portfolio-image"
                            src={project.thumbImage}
                            alt=""
                          />
                          <div
                            className="portfolio-overlay"
                          >
                            <button className="popup-ajax stretched-link border-0 p-0 ">
                              {" "}
                            </button>
                            <div className="portfolio-overlay-details">
                              {/* <h5 className="text-white text-5">
                                {project?.title}
                              </h5> */}
                              <span className="text-light">Click to View Details</span>
                            </div>
                          </div>
                        </div>
                        <div className="portfolio-details">
                          <h3 className="text-5 fw-600 mb-3">{project?.title}</h3>
                          <p className="text-muted mb-0">
                            {project?.projectInfo}
                          </p>
                          <span className={"text-dark fw-600 me-2"}>
                            Technologies:
                          </span>
                          {project?.technologies}
                        </div>
                      </div>
                    </div>
                  ) : '')}
              <div className="col-12 text-center text-lg-center">
                {(projectsData?.length > 0 && numOfProjectsShown < projectsData?.length) && <button
                  onClick={(e) => {
                    e.preventDefault();
                    setNumOfProjectsShown(numOfProjectsShown + 3);
                  }}
                  className="btn btn-dark rounded-0"
                  type="submit">
                  Load More
                  <span className="ms-3">
                    <i className="fas fa-arrow-down" />
                  </span>
                </button>}
                {((projectsData?.length >= initalItems) && numOfProjectsShown >= projectsData?.length) && <>
                  <p className="text-muted">No more projects to load</p>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setNumOfProjectsShown(initalItems);
                    }}
                    className="btn btn-dark rounded-0"
                    type="submit">
                    See Less
                    <span className="ms-3">
                      <i className="fas fa-arrow-up" />
                    </span>
                  </button>
                </>}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
