import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="section bg-dark text-white py-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center text-lg-start wow fadeInUp">
            <p className="mb-2 mb-lg-0">
              Copyright © {new Date().getFullYear()}. All Rights Reserved.
            </p>
          </div>
          <div className="col-lg-6 wow fadeInUp">
            <p className="mb-0 text-center text-lg-end">
              From Idea to Impact, Empowering Communities through Tech
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
