import React from "react";
import bioData from "../data/bio";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">About Me</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Know Me More
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            {/* <h2 className="text-8 fw-400 mb-3">
              My name is {" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                Elvis Sarfo
              </span>
            </h2> */}
            <p className="">
              I'm a full-stack Software Engineer based in Kumasi, Ghana, known for my my passion to impact world with technology.
              My journey in Software Engineering began 7 years ago, and since then, I've been dedicated to impacting the
              world around with innovative digital solutions and sharing impactful knowledge and skills.
            </p>
            <p className="">
              I believe that with a effective collaboration, we can build innovative
              digital solutions that tranforms the world around us into better one.
              Based on this belief, I've been able to work with some amazing
              people and organizations to build innovative digital solutions
              that solves real problems from the beginning of my journey.
            </p>
            <p className="">
              I started my journey as a Software Engineer in 2017, and through continous learning and development, I have worked
              on real word applicaitions to learn and attain skills in web development, mobile development, cloud computing. I have
              started a long term research in Data Science and Machine Learning and I'm looking forward to building innovative
              solutions in this field.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  6
                </span>
                <span className="wow heartBeat text-18" data-wow-delay="1.3s">
                  +
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Years of <span className="fw-700">Experiance </span>
                in Software Engineering
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-sm-12 col-md-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-dark fw-600 mb-0">{bioData.name}</p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href={bioData.email}>
                {bioData.email}
              </a>
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Phone:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href="tel:+233543075757">
                {bioData.phone}
              </a>
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">From:</p>
            <p className="text-4 text-dark fw-600 mb-0">{bioData.location}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
