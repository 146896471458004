import "./App.scss";
import WOW from "wowjs";
import AboutUs from "./components/About";
import Services from "./components/Services";
import Resume from "./components/Resume";
import Portfolio from "./components/Portfolio";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import { Tooltip } from "./components/Tooltip";
import StandardMenuHeader from "./components/themes/StandardMenu/Header";
import StandardMenuImgBgIntro from "./components/themes/StandardMenu/IntroImageBg";
import { logEvent } from "firebase/analytics";
import { analytics } from "./config/firebase";

function App() {
  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const [scrollTopVisible, setScrollTopVisible] = useState(false);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

    logEvent(analytics, "page_view", {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: document.title,
    });
  }, []);

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (
    <div style={{ position: "relative" }}>
      <div id="main-wrapper">
        <StandardMenuHeader />
        <main id="content" role="main">
          <StandardMenuImgBgIntro />
          <AboutUs></AboutUs>
          <Services></Services>
          <Portfolio></Portfolio>
          <Resume></Resume>
          <Testimonials></Testimonials>
          <Contact></Contact>
        </main>
        <Footer handleNavClick={handleNavClick}></Footer>
      </div>
      {/* back to top */}
      <Tooltip text="Back to Top" placement="left">
        <button
          id="back-to-top"
          className="rounded-circle"
          style={{ display: scrollTopVisible ? "inline" : "none" , border: "none"}}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <i className="fas fa-arrow-up"></i>
        </button>
      </Tooltip>
    </div>
  );
}

export default App;
