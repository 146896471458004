import React, { useEffect, useRef } from "react";

import Slider from "react-slick";
import ModalVideo from "react-modal-video";
import { videoSourceType } from "../data/projects";

const ProjectDetailsModal = ({ projectDetails, setIsOpen }) => {
  const sliderRef = useRef();

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    adaptiveHeight: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (projectDetails?.type === types.DOCUMENT) {
      sliderRef.current.slickGoTo(0);
    }
  }, [projectDetails, types.DOCUMENT]);

  return (
    <div className="project-details-modal">
      <div
        className="modal fade bg-dark-1 show"
        style={{ display: "block" }}
        tabIndex={-1}
        aria-hidden="true"
        onClick={() => setIsOpen(false)}
      >
        <div
          className="modal-dialog modal-xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className={"modal-content rounded-0"}>
            <div className="modal-body">
              <button
                type="button"
                className={"btn-close "}
                onClick={() => setIsOpen(false)}
              />
              <div className={"container ajax-container "}>
                <h2 className={"text-6 fw-600 text-center mb-4"}>
                  {projectDetails?.title}
                </h2>
                <div className="row g-4">
                  <div className="col-md-7">
                    {projectDetails?.type !== types.VIDEO &&
                      (<Slider  {...settings} ref={sliderRef}>
                        {/* <div className="item">
                            <img
                              className="img-fluid"
                              alt=""
                              src={projectDetails?.thumbImage}
                            />
                          </div> */}
                        {projectDetails?.sliderImages?.length > 0 &&
                          projectDetails?.sliderImages?.map(
                            (image, index) => (
                              <div className="item" key={index}>
                                <img
                                  style={{ objectFit: "contain", height: "400px", width: "100%" }}
                                  className="img-fluid"
                                  width="100%"
                                  height="400"
                                  alt=""
                                  src={image}
                                />
                              </div>
                            )
                          )}
                      </Slider>)}

                    {(projectDetails?.type === types.VIDEO) && (<>
                      {(projectDetails?.type === videoSourceType.LOCAL) ? (<video controls width="100%" height="400">
                        <source type="video/mp4" src={projectDetails?.videoSource} />
                      </video>) :
                        (<iframe width="100%" height="400"
                          src={projectDetails?.videoSource}
                          title="YouTube video player" frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen>
                        </iframe>)}
                    </>)}
                  </div>
                  <div className="col-md-5">
                    <h4 className={"text-4 fw-600"}>Project Description:</h4>
                    <p>{projectDetails?.projectInfo}</p>
                    <ul className={"list-style-2 "}>
                      <li>
                        <span className={"text-dark fw-600 me-2"}>
                          Client:
                        </span>
                        {projectDetails?.client}
                      </li>
                      <li>
                        <span className={"text-dark fw-600 me-2"}>
                          Technologies:
                        </span>
                        {projectDetails?.technologies}
                      </li>
                      <li>
                        <span className={"text-dark fw-600 me-2"}>
                          Industry:
                        </span>
                        {projectDetails?.industry}
                      </li>
                      <li>
                        <span className={"text-dark fw-600 me-2"}>
                          Date:
                        </span>
                        {projectDetails?.date}
                      </li>
                      <li>
                        <span className={"text-dark fw-600 me-2"}>
                          Links:
                        </span>
                        {projectDetails?.links?.map((link, index) => (
                          <a
                            href={link?.url}
                            className="btn btn-primary shadow-none rounded-0 px-3 py-1"
                            style={{ marginRight: "5px" }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link?.name}
                            <i className="fas fa-external-link-alt ms-1" />
                          </a>
                        ))}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsModal;
