export const filters = {
  DETAILED: "Details",
  MOCKUPS: "Mockups",
  YOUTUBE: "Youtube Videos",
  VIMEO: "Vimeo Videos",
};

export const types = {
  IMAGE: "image",
  VIDEO: "video",
  DOCUMENT: "document",
};

export const visibility = {
  PUBLIC: "public",
  PRIVATE: "private"
}

export const videoSourceType = {
  YOUTUBE: "youtube",
  LOCAL: "local",
}

export const projectsData = [
  {
    visibility: visibility.PUBLIC,
    index: 0,
    title: "Prodect Price Tracker",
    type: types.DOCUMENT,
    projectInfo:
      `
      Developing a web application that allows users to track the price of products on e-commerce websites.
      Used Next.js for server-side rendering,
      enabling faster page loads and improved SEO. It also simplifies
      the development process with features like automatic code splitting and server-side rendering.
      Connected to to rainforest API to get product details and price whilts using MySQL as your 
      relational database to store and manage data efficiently.
      `,
    client: "Personal Project",
    technologies: "Next.js • MySQL • JavaScript • APIs",
    industry: "Computer Science",
    date: "November 2023",
    links: [],
    sliderImages: [
      "images/projects/pt1.png",
      "images/projects/pt2.png",
      "images/projects/pt3.png",
      "images/projects/pt4.png",
    ],
    thumbImage: "images/projects/pt1.png",
    categories: [filters.DETAILED],
  },
  {
    visibility: visibility.PUBLIC,
    index: 0,
    title: "Nice Jobs",
    type: types.DOCUMENT,
    projectInfo:
      `
      Developing a job portal involves integrating various technologies 
      to create a seamless and efficient platform for both employers and job seekers.
      Used Next.js for server-side rendering, 
      enabling faster page loads and improved SEO. It also simplifies 
      the development process with features like automatic code splitting and server-side rendering.
      Use MySQL as your relational database to store and manage data efficiently.
      Design a well-structured database schema that includes tables for users, 
      job listings, applications, and any other relevant entities.
      `,
    client: "Personal Project",
    technologies: "Next.js • MySQL • JavaScript",
    industry: "Computer Science",
    date: "July 2023",
    links: [],
    sliderImages: [
      "images/projects/job.png",
      "images/projects/job1.png",
      "images/projects/job2.png",
      "images/projects/job3.png",
      "images/projects/job4.png",
      "images/projects/job5.png",
      "images/projects/job6.png",
      "images/projects/job7.png",
      "images/projects/job8.png",
      "images/projects/job9.png",
      "images/projects/job10.png",
      "images/projects/job11.png",
    ],
    thumbImage: "images/projects/job.png",
    categories: [filters.DETAILED],
  },
  {
    visibility: visibility.PUBLIC,
    index: 2,
    title: "Personal Mobility Intelligence Center (PMIC)",
    type: types.VIDEO,
    projectInfo:
      `The PMIC is a  product that gathers all insights related to the evolution of personal mobility to create a more intelligent, provide a decision-making tool, and more ― all in one easy-to-access hub.
      I developed the front-end (which include charts and other visualation tool) using Angular. I also worked on an ASP.NET Core API that served the front-end.`,
    client: "Kline and Company",
    technologies: "Angular, DevExtreme Library, ASP.NET Core",
    industry: "Computer Science",
    date: "2023",
    links: [{
      name: "Website",
      url: "https://klinegroup.com/home/personal-mobility-intelligence-center/",
    }],
    sliderImages: [],
    videoSource: "https://www.youtube.com/embed/YGdJs3d6PbQ?si=OUMLNcK0bbxGbMR-",
    videoSourceType: videoSourceType.YOUTUBE,
    thumbImage: "images/projects/pmic.png",
    categories: [filters.DETAILED],
  },
  {
    visibility: visibility.PUBLIC,
    index: 0,
    title: "Overtime Calculator",
    type: types.DOCUMENT,
    projectInfo:
      `Overtime Calculator is a web application that allows lecturers to calculate overtime pay.
      I developed the front-end with Javascript, HTML, CSS served from a node.js server with EJS template engine.
      Firebase firestore was used as the database for the application.
      `,
    client: "School Project",
    technologies: "Node.js • EJS • Firebase • HTML • Javascript • CSS",
    industry: "Computer Science",
    date: "2022",
    links: [{
      name: "Github",
      url: "https://github.com/Elvis-Sarfo/over-time-calculator",
    }],
    sliderImages: [
      "images/projects/claims.png",
      "images/projects/claims2.png",
      "images/projects/claims3.png",
    ],
    thumbImage: "images/projects/claims.png",
    categories: [filters.DETAILED],
  },
  {
    visibility: visibility.PUBLIC,
    index: 0,
    title: "Homomorphic Auth Algorithm",
    type: types.DOCUMENT,
    projectInfo:
      `I developed a Homomorphic Auth Algorithm with a newly proposed homomorphic encryption scheme by
      Asante et al., (2022). I used native javascript tp develop and auth scheme to test to speed and eficiency
      to benchmark the new scheme proposed in the research paper.`,
    client: "Research Project",
    technologies: "JavaScript • Node.js • EJS • HTML • CSS,",
    industry: "Computer Science",
    date: "2022",
    links: [{
      name: "Research Gate",
      url: "https://www.researchgate.net/publication/360280663_A_Symmetric_Probabilistic_Non-Circuit_Based_Fully_Homomorphic_Encryption_Scheme",
    }, {
      name: "View on Github",
      url: "https://github.com/Elvis-Sarfo/homomorphic_auth_algorithm",
    }],
    sliderImages: [
      "images/projects/homo.png"
    ],
    thumbImage: "images/projects/homo.png",
    categories: [filters.DETAILED],
  },
  {
    visibility: visibility.PUBLIC,
    index: 0,
    title: "Course Regitration Portal",
    type: types.DOCUMENT,
    projectInfo:
      `Developed a Course Registration Portal allowing over 10,000 students to register courses in the Interdisciplinary Department at AMMUSTED.
      Applied proficiency in Node.js for backend, Firebase for data management, and a PWA to enhance user experience.
      The project improved the efficiency and experience of course registration for 3 academic years.
      `,
    client: "Inter Disciplinary Department, AMMUSED",
    technologies: "Node.js • Firebase • PWA • JavaScript • HTML • CSS",
    industry: "Computer Science",
    date: "May 2021",
    links: [{
      name: "View Demo",
      url: "https://www.youtube.com/watch?v=HRjhRT-1tis&t=330s",
    }],
    sliderImages: [
      "images/projects/portal1.png",
      "images/projects/portal2.png",
      "images/projects/portal3.png",
      "images/projects/portal4.png",
      "images/projects/portal5.png",
    ],
    thumbImage: "images/projects/portal2.png",
    categories: [filters.DETAILED],
  },
  {
    visibility: visibility.PUBLIC,
    index: 0,
    title: "Jumandi Gas",
    type: types.DOCUMENT,
    projectInfo:
      `I Lead the development team to develop a system that allows users to order cooking gas from the nearest gas station.
      I develped two dashboards with react and also maintained the mobile apps. Firebase was adopted to
      handle realtime communication, authentication, notification with (FCM), and more.`,
    client: "Jumandi International",
    technologies: "React • Redux • Firebase",
    industry: "Computer Science",
    date: "2021",
    links: [{
      name: "Website",
      url: "Jumandi.com",
    }],
    sliderImages: [
      "images/projects/jumandi-1.png",
      "images/projects/jumandi-2.png",
      "images/projects/jumandi-3.png",
      "images/projects/jumandi-4.png",
    ],
    thumbImage: "images/projects/jumandi-1.png",
    categories: [filters.DETAILED],
  },
  {
    visibility: visibility.PUBLIC,
    index: 0,
    title: "Memo Manager",
    type: types.DOCUMENT,
    projectInfo:
      `Memo Manager is a serverless web application that allows users to create, edit, delete and share memos.
      I developed the front-end with Javascript, HTML and CSS, connected to a firebase as the backend.
      `,
    client: "School Project",
    technologies: "Firebase • Javascript • HTML • CSS",
    industry: "Computer Science",
    date: "2021",
    links: [{
      name: "Github",
      url: "https://github.com/Elvis-Sarfo/gra_school_project",
    }],
    sliderImages: [
      "images/projects/memo1.png",
      "images/projects/memo2.png",
      "images/projects/memo3.png",
      "images/projects/memo4.png",
      "images/projects/memo5.png",
    ],
    thumbImage: "images/projects/memo1.png",
    categories: [filters.DETAILED],
  },
  {
    visibility: visibility.PUBLIC,
    index: 0,
    title: "Clocking",
    type: types.DOCUMENT,
    projectInfo:
      `Clocking System is developed to track and keep attendance records of teachers
      and students in Senior High Schools. Teachers and students to register their
      arrival and departure time with a mobile app and standalone app respectively,
      all developed with flutter. Firebase served as the backend to data accross the
      apps and Google Map APIs used to get he location and calculation location proximty
      of devices.
      `,
    client: "School Project",
    technologies: "Flutter • Firebase • Google Map API",
    industry: "Computer Science",
    date: "2020",
    links: [{
      name: "Landing Page",
      url: "https://elvis-sarfo.github.io/clocking-website/",
    }],
    sliderImages: [
      "images/projects/clocking-2.png",
      "images/projects/clocking-5.png",
      "images/projects/clocking-6.png",
      "images/projects/clocking-7.png",
      "images/projects/clocking-8.png",
      "images/projects/clocking-9.png",
      "images/projects/clocking-10.png",
      "images/projects/clocking-11.png",
      "images/projects/clocking-12.png",
      "images/projects/clocking-13.png",
      "images/projects/clocking-14.png",
      "images/projects/clocking-15.png",
      "images/projects/clocking-16.png",
      "images/projects/clocking-17.png",
      "images/projects/clocking-18.png",
    ],
    thumbImage: "images/projects/clocking-1.png",
    categories: [filters.DETAILED],
  },
  {
    visibility: visibility.PUBLIC,
    index: 0,
    title: "Godlives Delivery",
    type: types.DOCUMENT,
    projectInfo:
      `I developed admin dashboards to manage food orders and deliveries from the super admin and restaurant admins.
       I also maintained a node.js API that served as a pivot for all the applications which includes mobile apps built with flutter,
    `,
    client: "Citi Foods (Sweden)",
    technologies: "Node.js • Angular • Firebase • MongoDB • One Signal • Flutter",
    industry: "Godlive Delivery (now Citi Foods)",
    date: "May, 2019",
    links: [{
      name: "Website",
      url: "https://godlives.godlivesdelivery.se/",
    }],
    sliderImages: [
      "images/projects/godlives-1.png",
      "images/projects/godlives-2.webp",
      "images/projects/godlives-3.webp",
    ],
    thumbImage: "images/projects/godlives-1.png",
    categories: [filters.DETAILED],
  },
  {
    visibility: visibility.PUBLIC,
    index: 1,
    title: "Emergency Response Assistance",
    type: types.VIDEO,
    projectInfo:
      `A GDSC solution challenge winner, a web application that allows users to request for emergency response assistance from the nearest hospital.
     I Lead 3 teammates through planning, designing and developing of the project.
     Developed a dashboard powered by Node.js. Combined firebase and scoket.io to create a real-time communication
      between the dashboard and the mobile app.
     `,
    client: "GDSC Solution Challenge",
    technologies: "Node.js • Express • EJS • Firebase • Socket.io • Andriod (Java) • JQuery",
    industry: "Computer Science",
    date: "2020",
    links: [],
    sliderImages: [
      "images/projects/era-2.png",
      "images/projects/era-3.png",
      "images/projects/era-4.png"
    ],
    videoSource: "https://www.youtube.com/embed/tzRS94nivzo?si=1SIzFukDxmHblxHy",
    videoSourceType: videoSourceType.YOUTUBE,
    thumbImage: "images/projects/era.png",
    categories: [filters.DETAILED],
  },
  {
    visibility: visibility.PUBLIC,
    index: 0,
    title: "Numericalc",
    type: types.DOCUMENT,
    projectInfo:
      `I developed an app that allow students to perform numerical computation with step-by-step explaination.
      The project developed with native HTML, CSS and Javascript and compile into andriod app using the webview API.
      It was used by over 1200 students on UEW Kumasi Campus`,
    client: "School Project",
    technologies: "JAvaScript • HTML • CSS • Andriod (Java)",
    industry: "Computer Science",
    date: "July, 2019",
    links: [{
      name: "View on Github",
      url: "https://github.com/Elvis-Sarfo/numericalc",
    }],
    sliderImages: [
      "images/projects/numericalc-1.png",
      "images/projects/numericalc-2.png",
      "images/projects/numericalc-3.png",
      "images/projects/numericalc-4.png",
      "images/projects/numericalc-5.png",
      "images/projects/numericalc-6.png",
    ],
    thumbImage: "images/projects/numericalc-1.png",
    categories: [filters.DETAILED],
  }
];
// {
//   visibility: visibility.PRIVATE,
//   index: 0,
//   title: "Sure Betcodes Website ",
//   type: types.DOCUMENT,
//   projectInfo:
//     `asdfghj`,
//   client: "Nana Yaw (Surebet Code Owner)",
//   technologies: "iOS • HTML5 • CSS3 • PHP • Java",
//   industry: "Computer Science",
//   date: "July 16, 2019",
//   links: [{
//     name: "www.example.com",
//     url: "https://www.example.com",
//   }],
//   sliderImages: [
//     "images/projects/project-1.jpg",
//     "images/projects/project-2.jpg",
//   ],
//   thumbImage: "images/projects/project-5.jpg",
//   categories: [filters.DETAILED],
// },