const testimonials = [
  {
    name: "Duncan Lawie",
    position: "Vice President, Technology at Kline & Company",
    src: "images/testimonial/duncan.jpeg",
    desc: "“Elvis is valued by both his Technology colleagues and teams elsewhere in the organisation. He communicates well, delivers good work and has handled unplanned workloads cheerfully. I am pleased to have him as part of Kline.”",
  },
  {
    name: "Zotor Wisdom",
    position: "Team Lead at FarmAsyst, Ghana",
    src: "images/testimonial/wisdom.jpg",
    desc: " “Elvis consistently demonstrates exceptional leadership, driving the team with a perfect blend of technical expertise, creativity, and a commitment to achieving impactful results”",
  }
]
export default testimonials;