import React from "react";
import { Link } from "react-scroll";
import { Parallax } from "react-parallax";
import Typewriter from "typewriter-effect";
import { scrollDuration } from "../../../config/commonConfig";

const StandardMenuImgBgIntro = () => {
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />
        <Parallax
          bgImage="images/intro-bg.jpg"
          bgImageAlt="Intro"
          strength={200}
        >
          <div className="hero-content d-flex fullscreen-with-header py-5">
            <div className="container my-auto py-4">
              <div className="row">
                <div className="col-lg-7 text-left order-1 
                order-lg-0 d-flex flex-column align-items-start
                justify-content-center">
                  <h1 className="text-10 text-white fw-200 mb-0 py-0">
                    Hey there!
                  </h1>
                  <h1 className="text-10 text-white fw-500 mb-5 lh-lg">
                    I'm <span className="border-bottom border-3 border-primary">Elvis Sarfo</span> (Kanta)
                  </h1>
                  <div className="text-5 text-white fw-200 mb-0 lh-sm">
                    I'm a/an {" "}
                    <span className="text-5 text-white fw-500 mb-0 ms-n1 lh-sm">
                      <Typewriter
                        options={{
                          strings: ["Software Engineer", "Full-Stack Developer", "Entrepreneur", "Instructor", "GDG Co-Organizer"],
                          autoStart: true,
                          loop: true,
                          wrapperClassName: "Typewriter"
                        }}
                      />
                    </span>
                  </div>

                  <p className="text-5 text-white fw-200 mb-0 lh-sm">
                    I am passionate about impacting the world around me by building innovative
                    digital solutions that solves real problems and sharing impactful knowledge and skills.
                  </p>
                  <Link
                    className="btn btn-primary fw-600 rounded-0 smooth-scroll mt-3"
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    to="portfolio"
                  >
                    View My Works
                  </Link>{" "}
                </div>
                <div className="col-lg-5 text-center align-self-center mb-4 mb-lg-0 order-0 order-lg-1">
                  <div className="bg-light banner-profile rounded-pill d-inline-block p-2 shadow-lg wow zoomIn">
                    <img
                      className="img-fluid rounded-pill d-block"
                      src="images/kanta.png"
                      title="I'm Elvis Sarfo (Kanta)"
                      alt="'m Elvis Sarfo (Kanta)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Link
              className="scroll-down-arrow text-light smooth-scroll"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer" }}
              to="about"
            >
              <span className="animated">
                <i className="fas fa-arrow-down" />
              </span>
            </Link>{" "}
          </div>
        </Parallax>
      </div>
    </section>
  );
};

export default StandardMenuImgBgIntro;
