import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      "name": "Front-End",
      "desc": `Proficient in developing engaging user interfaces using 
      <strong> React.js</strong>, 
      <strong> Next.js</strong>, 
      <strong> Angular</strong> and 
      <strong>Flutter</strong>. 
      Demonstrated experience in integrating data visualization and optimizing application performance.`,
      "icon": "fas fa-palette"
    },
    {
      "name": "Back-End",
      "desc": `Skilled in server-side development with 
      <strong> Node.js</strong>, <strong> ASP.NET Core</strong> and 
      expertise in managing databases, including <strong>SQL</strong>, 
      <strong>MongoDB</strong> and <strong>Firebase</strong>.`,
      "icon": "fas fa-server"
    },
    {
      "name": "Software Engineering",
      "desc": `
      Collaborate with teams to gather user requirements, design and implement efficient code, 
      and ensure seamless integration of software components.`,
      "icon": "fas fa-pencil-ruler"
    },
    {
      "name": "Cloud Computing / DevOps",
      "desc": `Well-versed in configuring and seamlessly integrating 
      Google Cloud business and software solutions. Experienced in using <strong>Docker</strong> and
      <strong>Cloud Build</strong> to deploy applications to <strong>Cloud Run</strong>.`,
      "icon": "fas fa-cloud"
    },
    {
      "name": "Machine Learning",
      "desc": `Engaged in research endeavors related to homomorphic 
      computing and Machine learning (Deep Learning).`,
      "icon": "fas fa-paint-brush"
    }
  ]
    ;

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0" dangerouslySetInnerHTML={{ __html: service.desc }} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
